<template>
  <HeartysanCategoryEditingSection
    :HC="HC"
    @save="save"
  />
</template>

<script>
  import HeartysanCategoryEditingSection from "./HeartysanCategoryEditingSection/HeartysanCategoryEditingSection";
  import {HeartysanCategory} from "../../models/HeartysanCategory";
  import {heartysanCategoryMixin} from "@/mixins/heartysanMixins/heartysanCategoryMixin";

  export default {
    name: "HeartysanCategoryEditing",
    components: {
      HeartysanCategoryEditingSection,
    },

    mixins: [heartysanCategoryMixin],

    data() {
      return {
        HC: new HeartysanCategory(),
      }
    },

    mounted() {
      this.init()
    },

    methods: {
      init() {
        let id = this.$route.params.id
        this.$store.dispatch('getHeartysanCategory', id).then((response) => {
          let item = this.getRespData(response)
          this.HC.setItem(item)
        }).catch(error => this.createErrorLog(error))
      },

    }

  }
</script>

<style scoped>

</style>
